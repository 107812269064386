(function($){

  window.dalloLawGroup = {};

  var DALLO = window.dalloLawGroup;

  var $window = $(window);

  DALLO.init = function(){
    DALLO.setElements();
    DALLO.heroHeight();
    DALLO.modals();
    DALLO.slider();
    DALLO.mobileNav();
    DALLO.headerSearch();
    DALLO.footerCarousel();
    DALLO.buildingBlockCarousel();
    DALLO.responsiveVids();
    DALLO.pgWithSidebar();
    DALLO.maps();
    DALLO.attorneySlider();
    //DALLO.fancySelect();
    // DALLO.mixing();
    // DALLO.skrollr();
  };

  DALLO.setElements = function(){
    DALLO.elems             = {};

    DALLO.elems.href        = window.location.pathname;
    DALLO.elems.previousUrl = document.referrer;

  };


  DALLO.fancySelect = function() {

    // Using Chosen here : http://harvesthq.github.io/chosen/

    if (!$('select').length) { return; }

    var select = $('select');

    // if there are less than ten items in the select, don't show the search feature
    select.chosen({disable_search_threshold: 10});
  };  


  DALLO.toolTip = function() {

    $('.tooltip').tooltipster({
      touchDevices: false,
      arrow: false,
      position: 'top',
      theme: 'dallo-tooltip-theme',
      contentAsHTML: true,
      offsetY : 20
    });

  };


 DALLO.buildingBlockCarousel = function(){
    
    if (!$('.img-carousel.owl-carousel').length) { return; }

    var looping,
        speed,
        pause,
        start,
        carouselWrap;

   carouselWrap  = $('.carousel-wrap');

   // this is collecting the various options the admin has access to,
   // we're going to apply these to the slider init function below
   speed   = carouselWrap.attr('data-speed');
   // since the value returned here is either 1 or 0, its updated to either true or false to work for the slider
   looping = carouselWrap.attr('data-loop') == 1 ? true : false;
   pause   = carouselWrap.attr('data-pause');
   // since the value returned here is either 1 or 0, its updated to either true or false to work for the slider
   start   = carouselWrap.attr('data-start') == 1 ? true : false;


    $('.img-carousel').owlCarousel({
        loop: looping,
        margin: 0,
        nav: true,
        items: 1,
        autoplay : start,
        autoplayHoverPause : true,
        navSpeed : speed,
        dotSpeed : speed,
        smartSpeed : speed,
        autoplaySpeed : speed,
        autoplayTimeout : pause,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:5
            }
        }        
    });  

    // we want the speed of the transition to always be the same, so we have navSpeed, dotSpeed and smartSpeed the same
    // autoplayTimeout is how much time between slides, if they're being autoplayed

  };  


  DALLO.attorneySlider = function(){

    $('.attorney-thumb-img').first().addClass('active');

    $('.attorney-thumb-img').on('click', function() {
      $('.attorney-thumb-img').removeClass('active');
      $(this).addClass('active');
    });
  
    $('.attorney-thumb-slider').owlCarousel({
        loop: false,
        margin: 0,
        nav: true,
        responsive:{
            0:{
                items:3
            },
            1440:{
                items:4
            }
        },
        onInitialized : function() {
          DALLO.toolTip();
        }
    });

    function attorneySliderSettings() {

      if ($('body').width() > 1199) {

        $('.attorney-slider').trigger('destroy.owl.carousel');

        $('.attorney-slider').owlCarousel({
            loop: false,
            margin: 0,
            nav: true,
            items: 1,
            URLhashListener:true,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false
        }); 

      } else {

        $('.attorney-slider').trigger('destroy.owl.carousel');

        $('.attorney-slider').owlCarousel({
            loop: false,
            margin: 0,
            nav: true,
            items: 1,
            URLhashListener:true,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true            
        }); 

      }

    }

    attorneySliderSettings(); 

    $window.on('resize', attorneySliderSettings );     

  };


  DALLO.maps = function() {

        if (!$('.acf-map').length) { return false; }

      /*
      *  render_map
      *
      *  This function will render a Google Map onto the selected jQuery element
      *
      *  @type  function
      *  @date  8/11/2013
      *  @since 4.3.0
      *
      *  @param $el (jQuery element)
      *  @return  n/a
      */


      function render_map( $el ) {

        // var
        var $markers = $el.find('.marker');
        var dragging = true;

        if (Modernizr.touch) {
          dragging = false;
        }

        // vars
        var args = {
          zoom    : 16,
          scrollwheel: false,
          center    : new google.maps.LatLng(0, 0),
          mapTypeId : google.maps.MapTypeId.ROADMAP,
          draggable: dragging
        };

        // create map
        var map = new google.maps.Map( $el[0], args);


        // add a markers reference
        map.markers = [];

        // add markers
        $markers.each(function(){

            add_marker( $(this), map );

        });

        // center map
        center_map( map );

      }

      /*
      *  add_marker
      *
      *  This function will add a marker to the selected Google Map
      *
      *  @type  function
      *  @date  8/11/2013
      *  @since 4.3.0
      *
      *  @param $marker (jQuery element)
      *  @param map (Google Map object)
      *  @return  n/a
      */

      function add_marker( $marker, map ) {

        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        var mapUrl = $marker.attr('data-map-url');

        var infowindow = new google.maps.InfoWindow({
            content: '<p class="text-align-left">' + mapUrl + '<br><a href="http://google.com/maps/search/' + mapUrl + '" target="_blank">Get Directions</a></p>'
        });

        // create marker
        var marker = new google.maps.Marker({
          position  : latlng,
          map     : map
        });

        // add to array
        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        // if( $marker.html() )
        // {
          // create info window
          // var infowindow = new google.maps.InfoWindow({
          //   content   : 'this is content'
          // });

          // show info window when marker is clicked
          google.maps.event.addListener(marker, 'click', function() {

            infowindow.open( map, marker );

          });
        // }


        // google.maps.event.addListener(marker, 'click', function() {
        //   window.open(mapUrl, '_blank');
        // });

      }

      /*
      *  center_map
      *
      *  This function will center the map, showing all markers attached to this map
      *
      *  @type  function
      *  @date  8/11/2013
      *  @since 4.3.0
      *
      *  @param map (Google Map object)
      *  @return  n/a
      */

      function center_map( map ) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each( map.markers, function( i, marker ){

          var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

          bounds.extend( latlng );

        });

        // only 1 marker?
        if( map.markers.length ===1 )
        {
          // set center of map
            map.setCenter( bounds.getCenter() );
            map.setZoom( 16 );
        }
        else
        {
          // fit to bounds
          map.fitBounds( bounds );
        }

      }

      /*
      *  document ready
      *
      *  This function will render each map when the document is ready (page has loaded)
      *
      *  @type  function
      *  @date  8/11/2013
      *  @since 5.0.0
      *
      *  @param n/a
      *  @return  n/a
      */

        $('.acf-map').each(function(){

          render_map( $(this) );
          $('.location-items').matchHeight();

        });

  };



  DALLO.pgWithSidebar = function(){

    if (!$('.pg-with-sidebar-wrap').length) { return; }

    $('.pg-with-sidebar-item').matchHeight();


    function sidebarPosition() {

      var sidebar     = $('.sidebar');
      var mainContent = $('.main-content');

      if ($('body').width() > 1199) {
        sidebar.insertBefore(mainContent);
      } else {
        sidebar.insertAfter(mainContent);
      }

    }

    function falseWrapPositioning() {
      var container    = $('.banner .container');
      var sidebar      = $('.sidebar');
      var sidebarInner = $('.sidebar-inner');
      var mainContent  = $('.main-content');
      var left         = container[0].offsetLeft + 55;
      var right        = left;
      var mainWidth;


      if ($('body').width() > 1199) {
        sidebar.css('padding-left', left);
        mainWidth = document.documentElement.clientWidth - sidebar[0].offsetWidth;
        mainContent.css('width', mainWidth);
        mainContent.css('padding-right', right);
      } else {

        sidebar.css('padding-left', '');
        mainContent.css('width', '');
        mainContent.css('padding-right', '');

      } 

    }

    sidebarPosition();
    falseWrapPositioning();

    $window.on('resize', function() {
      sidebarPosition();
      falseWrapPositioning();
    });




    var menuParent = $('.menu-item-has-children');

    menuParent.each(function() {
      var $this = $(this);

      $('<span class="menu-arrow"></span>').appendTo($this);
    });

    var menuArrow = $('.menu-arrow');

    menuArrow.on('click', function() {
      var $this = $(this);
      var submenu = $this.siblings('.sub-menu');

      $this.toggleClass('active');

      submenu.slideToggle({ // this helps control the updating of the sidebar and content height w/ the submenu animation
        duration: 300,
        start: function() {
          $.fn.matchHeight._update();
        },
        done: function() {
          $.fn.matchHeight._update();
        }        
      });



    });
    

  };  


  DALLO.headerSearch = function(){
    var search = $('.header-search');
    var searchForm = search.find('form');
    var searchField = search.find('.search-field');
    var searchSubmit = search.find('.search-submit');

    search.addClass('inactive');

    searchForm.on('submit', function(e) {

      if (search.hasClass('inactive')) {
        search.removeClass('inactive');
        search.addClass('active');
        e.preventDefault();
        searchField.attr('required', true);

        window.setTimeout(function() {
          searchField.focus();
        }, 400);
      }

    });

  };  


  DALLO.mobileNav = function() {

    var nav = $('.main-nav');

    function mobileNavHeight() {
      var headerHeight = $('.banner').innerHeight();
      var windowHeight = window.innerHeight;
      var finalHeight = windowHeight - headerHeight;

      if ($('body').width() < 992) {
        nav.css('height', finalHeight + 'px');        
        nav.css('top', headerHeight + 'px'); 
      } else {
        nav.css('height', '');
        nav.css('top', '');
      }
    }

    function scrollLocking() {

      if ($('body').width() < 992 && $('.main-nav').hasClass('active-nav')) {
        $('html, body').css({
            'overflow': 'hidden',
            'height': '100%'
        });        
      } else {
        $('html, body').css({
            'overflow': '',
            'height': ''
        });        
      }

    }

    mobileNavHeight();

    $(window).on('resize', function() {
      mobileNavHeight();
      scrollLocking();
    });


    $('.nav-trigger').on('click', function() {
      $(this).toggleClass('active-nav');
      nav.toggleClass('active-nav');
      scrollLocking();
    });

  };


  DALLO.slider = function(){
    
    if (!$('.slider.owl-carousel').length) { return; }

    var looping,
        speed,
        pause,
        start,
        sliderWrap;

   sliderWrap  = $('.slider-wrap');

   // this is collecting the various options the admin has access to,
   // we're going to apply these to the slider init function below
   speed   = sliderWrap.attr('data-speed');
   // since the value returned here is either 1 or 0, its updated to either true or false to work for the slider
   looping = sliderWrap.attr('data-loop') == 1 ? true : false;
   pause   = sliderWrap.attr('data-pause');
   // since the value returned here is either 1 or 0, its updated to either true or false to work for the slider
   start   = sliderWrap.attr('data-start') == 1 ? true : false;


    $('.slider').owlCarousel({
        loop: looping,
        margin: 0,
        nav: true,
        items: 1,
        autoHeight : true,
        autoplay : start,
        autoplayHoverPause : true,
        navSpeed : speed,
        dotSpeed : speed,
        smartSpeed : speed,
        autoplaySpeed : speed,
        autoplayTimeout : pause
    });  

    // we want the speed of the transition to always be the same, so we have navSpeed, dotSpeed and smartSpeed the same
    // autoplayTimeout is how much time between slides, if they're being autoplayed

  };  



  DALLO.footerCarousel = function() {


    $('.footer-carousel-inner').owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:5
            }
        }
    }); 

  };


  DALLO.responsiveVids = function() {

    if (!$('.col-content').length) { return; }

    var videoWrapper = $('.col-content');

    videoWrapper.fitVids();
  };


  DALLO.heroHeight = function(){

    if (!$('.hero.hero-full').length) { return; }

    // this is here to fix the 100vh bug in mobile safari.
    // basically mobile safari has a bottom status bar that isn't included in the 100vh calculation
    // this results in the 100vh being more like 100vh + 70px. the function below fixes that.
    function mobileHeaderHeight() {
      var hero = $('.hero');
      var headerHeight = $('.banner').innerHeight();
      var windowHeight = window.innerHeight;
      var finalHeight = windowHeight - headerHeight;

      hero.css('min-height', finalHeight + 'px');
    }

    mobileHeaderHeight();

    $(window).on('resize', mobileHeaderHeight);


  }; 


  DALLO.mixing = function() {

    // http://mixitup.kunkalabs.com
    if (!$('.mixing-container').length) { return; }

    $('.mixing-container').mixItUp();

  };


  DALLO.modals = function(){

    if (!$('.video-modal-trigger').length && !$('.general-modal-trigger').length && !$('.image-modal-trigger').length) { return; }

    var videoModal = $('.video-modal-trigger');
    var generalModal = $('.general-modal-trigger');
    var imageModal = $('.image-modal-trigger');


    videoModal.magnificPopup({
      removalDelay: 500, //delay removal by X to allow out-animation
      type: 'iframe',
      callbacks: {
        beforeOpen: function() {
           this.st.mainClass = this.st.el.attr('data-effect') || 'mfp-zoom-in';
        }
      },
      midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    }); 

    generalModal.magnificPopup({
      removalDelay: 500, //delay removal by X to allow out-animation
      callbacks: {
        beforeOpen: function() {
           this.st.mainClass = this.st.el.attr('data-effect') || 'mfp-zoom-in';
        }
      },
      midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    }); 

    imageModal.magnificPopup({
      removalDelay: 500, //delay removal by X to allow out-animation
      type: 'image',
      callbacks: {
        beforeOpen: function() {
           this.st.mainClass = this.st.el.attr('data-effect') || 'mfp-move-from-top';
        }
      },
      midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });               

  };    


  DALLO.skrollr = function(){

    var s = skrollr.init({
      mobileCheck: function() {
         //hack - forces mobile version to be off
         return false;
     }
    });

  };






$window.load(function(){
  DALLO.fancySelect();
});

$(document).ready(function(){

  DALLO.init();

});//close document ready


})(window.jQuery);
